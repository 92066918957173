import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const API_URL = "https://z98n739ltk.execute-api.eu-north-1.amazonaws.com/dev"

const checkBingo = (entries) => {
  const rowCrosses = [0, 0, 0, 0, 0]
  const colCrosses = [0, 0, 0, 0, 0]
  const diagCrosses = [0, 0]
  for (let i = 0; i < entries.length; i++) {
    if(entries[i].crossedOut) {
      const col = i % 5
      const row = Math.floor(i / 5)
      colCrosses[col]++
      rowCrosses[row]++
      if (col===row) {
        diagCrosses[0]++
      }
      if (col===4-row) {
        diagCrosses[1]++
      }
    }
  }

  const resultEntries = []
  for (let i = 0; i < entries.length; i++) {
    let winning = false
    if(entries[i].crossedOut) {
      let col = i % 5
      let row = Math.floor(i / 5)
      if (colCrosses[col] === 5) {
        winning = true
      }
      if (rowCrosses[row] === 5) {
        winning = true
      }
      if (col===row && diagCrosses[0] === 5) {
        winning = true
      }
      if (col===4-row && diagCrosses[1] === 5) {
        winning = true
      }
    }
    resultEntries.push({ ...entries[i], winning: winning })
  }

  return resultEntries
}

const saveState = (entries) => {
  localStorage.setItem('entries', JSON.stringify(entries))
}

export const initBingo = createAsyncThunk(
  'bingo/init',
  async (arg, thunkAPI) => {
    if (localStorage.hasOwnProperty('entries')) {
      return JSON.parse(localStorage.getItem('entries'))
    } else {
      const result = await axios.get(`${API_URL}/card`)
      const { entries } = result.data
      saveState(entries)
      return entries
    }
  }
)

export const bingoSlice = createSlice({
  name: 'bingo',
  initialState: {
    entries: []
  },
  reducers: {
    crossOut: (state, action) => {
      const entries = checkBingo(state.entries.map(entry =>
        entry.entryId === action.payload ? { ...entry, crossedOut: !entry.crossedOut } : entry ))
      saveState(entries)
      return {
        ...state,
        entries,
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initBingo.fulfilled, (state, action) => {
      state.entries = action.payload
    })
  }
})

export const { crossOut } = bingoSlice.actions

export default bingoSlice.reducer
