import { useEffect } from 'react'
import { Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'

import { crossOut, initBingo } from './bingoReducer'

import './BingoCard.css'

const entriesToRows = (entries) => {
  const rows = []
  for (let i = 0; i < 5; i+= 1) {
    rows.push(entries.slice(i*5, (i+1)*5));
  }
  return rows
}

const BingoCardGridEntry = ({ entry }) => {
  const dispatch = useDispatch()
  const { entryId, track, crossedOut, winning } = entry
  const { title, artist, released, spotifyURL } = track

  const onClickSpotify = (event) => {
    event.stopPropagation()
  }

  return (
    <td
      className={`BingoCardGridEntry ${winning ? "winning" : ""}`}
      onClick={e => dispatch(crossOut(entryId))}>
      <div className={`BingoCardGridEntryDot ${crossedOut ? "crossed-out" : ""}`}>
        <span>{title},</span>
        <span>{artist}, {released.split("-")[0]}</span>
      </div>
      <a className="SpotifyLink" onClick={onClickSpotify} href={spotifyURL} target="_blank" rel="noreferrer">Spotify</a>
    </td>
  )
}

const BingoCardGrid = () => {
  const entries = useSelector(state => state.bingo.entries)
  const rows = entriesToRows(entries)
  const isBingo = entries.filter(e => e.winning).length > 0

  return (
    <>
      <Table className="BingoCardTable" responsive>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {row.map(entry => <BingoCardGridEntry key={entry.entryId} entry={entry} />)}
            </tr>
          ))}
        </tbody>
      </Table>
      {isBingo && <ShoutBingo />}
    </>
  )
}

const ShoutBingo = () => {
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  })
  return (
    <Container className="ShoutBingo" style={{ backgroundImage: `url(${process.env.PUBLIC_URL+ "/shout_bingo.png"})`}} />
  )
}

const BingoCard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initBingo())
  })
  return (
    <Container className="BingoCard">
      <Container className="BingoCardHeader" style={{ backgroundImage: `url(${process.env.PUBLIC_URL+ "/bingo_header.png"})`}} />
      <BingoCardGrid />
    </Container>
  )
}

export default BingoCard
